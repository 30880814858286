export const VOTE_2020_CAMPAIGN = 'vote2020'

export const CHECK_REGISTER_PATH = '/check'
export const COMMIT_PATH = '/commit'
export const CREW = '/crew'
export const DELETE_PATH = '/delete'
export const INDEX_PATH = '/'
export const INTRO_PATH = '/intro'
export const INVITE_PATH = '/invite'
export const JOIN_PATH = '/join'
export const LOGIN_PATH = '/login'
export const LOGOUT_PATH = '/logout'
export const MAILIN_PATH = '/mailin'
export const PUSH_PATH = '/push'
export const REGISTER_PATH = '/register'
export const VOTE_PATH = '/vote'
export const DASHBOARD_PATH = '/dashboard'
export const USER_PHOTO = '/user-photo'
export const DESKTOP_PATH = '/desktop'
export const TOU_PATH = '/tou'
export const PRIVACY_PATH = '/privacy'
export const LOCATIONS_PATH = '/locations'
export const FAQ_PATH = '/faq'

export const AUTH_ROUTES = [DASHBOARD_PATH, DELETE_PATH, USER_PHOTO]
export const MOBILE_ONLY_ROUTES = [DASHBOARD_PATH, DELETE_PATH, USER_PHOTO, LOGIN_PATH]
// Universal routes work on both Desktop/Mobile in auth/unauthed state
export const UNIVERAL_ROUTES = [TOU_PATH, PRIVACY_PATH, LOGOUT_PATH, LOCATIONS_PATH, FAQ_PATH]
