import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import * as Sentry from '@sentry/node'

import { StoreProvider, mainReducer, useStore, useScreenOrientation } from '../hooks'
import initialState from '../reducers/initialState'
import '../styles/app.scss'
import { initFirebase } from '../reducers/actions'
import { canShare } from '../lib/sharing'

import Auth from '../components/Auth'
import ErrorBoundary from '../components/ErrorBoundary'
import ScreenOrientation from '../components/ScreenOrientation'
import WarningScreen from '../components/WarningScreen'

import { initStorage } from '../lib/asyncStorage'

const LoadFirebase = () => {
  const [{ auth }, dispatch] = useStore()
  useEffect(() => {
    const fn = async () => {
      await initFirebase(auth, dispatch)
    }
    fn()
  }, [])
  return null
}

const App = (props) => {
  // eslint-disable-next-line react/prop-types
  const { Component, pageProps } = props
  const router = useRouter()
  const screenOrientation = useScreenOrientation()
  const [errorType, setErrorType] = useState('')
  const [initted, setInitted] = useState(false)

  useEffect(() => {
    // Sentry error logging setup
    if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
      Sentry.init({
        enabled: true, //process.env.NODE_ENV === 'production',
        dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
        environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
        release: process.env.CURRENT_VERSION,
        beforeSend(event, hint) {
          event.tags = event.tags || { swallowed: false }
          let swallow = event.tags.swallowed
          try {
            //console.log('sentry event', event)
            // if it's an unhandled async rejection, just log it
            event?.exception?.values?.forEach((entry) => {
              if (entry.mechanism?.type === 'onunhandledrejection') {
                swallow = true
              }
            })
            if (hint && !errorType) {
              // Filter out some errors we don't care about
              const error = hint.originalException
              let errorMessage
              if (error && typeof error === 'string') {
                errorMessage = error
              } else if (error && error instanceof Error) {
                errorMessage = error.message
              }
              if (errorMessage) {
                if (errorMessage.match(/NotAllowedError/i)) {
                  // Sentry.captureMessage(`not allowed warning: ${errorMessage}`)
                  setErrorType('not-allowed')
                  swallow = true
                }

                if (errorMessage.match(/storage|cookies|indexed|quota/i)) {
                  // Sentry.captureMessage(`cookie warning: ${errorMessage}`)
                  setErrorType('cookies')
                  swallow = true
                }

                const isWhitelisted = errorMessage.match(
                  /IDBDatabase|insufficient|network|storage|firebase|internal|indexed|analytics|chunk|non-error|quota|NotAllowedError|Unrecognized token '!'/i
                )
                if (isWhitelisted) {
                  swallow = true
                }
              }
            }
          } catch (err) {
            // error in the error handler?
            Sentry.captureMessage('error in the beforeSend error handler')
          }
          if (event.exception && !swallow && process.env.NODE_ENV !== 'development') {
            Sentry.showReportDialog({ eventId: event.event_id })
          }
          event.tags.swallowed = swallow
          return event
        },
      })
    }
    const asyncHelper = async () => {
      // init localforage
      await initStorage()
      setInitted(true)
    }
    asyncHelper()
  }, [])

  // if (!initted) return null

  // INFO: The < ScreenRotation > code breaks iPhone load
  if (canShare && (screenOrientation === 'landscape-primary' || screenOrientation === 'landscape-secondary')) {
    return (
      <ErrorBoundary>
        <ScreenOrientation />
      </ErrorBoundary>
    )
  }

  if (errorType === 'not-allowed' || errorType === 'cookies') {
    return (
      <WarningScreen setError={setErrorType}>
        <h3>Error</h3>
        <p>Cookies appear to be disabled in your browser.</p>
        <p>Please re-enable cookies before proceeding with Landslide. </p>
      </WarningScreen>
    )
  }

  // Disable auth redirect for development purposes if needed by setting NEXT_PUBLIC_SUPPRESS_AUTH_REDIRECT=true
  if (process.env.NODE_ENV === 'development' && process.env.NEXT_PUBLIC_SUPPRESS_AUTH_REDIRECT === 'true') {
    return (
      <ErrorBoundary>
        <StoreProvider initialState={initialState} reducer={mainReducer}>
          <Component {...pageProps} />
        </StoreProvider>
      </ErrorBoundary>
    )
  }

  return (
    <ErrorBoundary>
      <StoreProvider initialState={initialState} reducer={mainReducer}>
        <LoadFirebase />
        <Auth path={router.pathname}>
          <Component {...pageProps} />
        </Auth>
      </StoreProvider>
    </ErrorBoundary>
  )
}
export default App
